import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const ComingSoon = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleNotify = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        "https://web-production-8a33.up.railway.app/api/v1/public/submit",
        {
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data?.message);
      setEmail("");
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  // Call the function to execute the POST request

  return (
    <section className="w-full bg-home-bg overflow-auto scroller h-screen bg-cover bg-no-repeat">
      <div className="flex flex-col justify-center items-center w-full h-full relative">
        <div className="w-full max-w-[534px] flex flex-col items-center gap-5 md:gap-[50px] px-4 md:px-0 mt-20">
          <img src="/images/block-hire.svg" alt="logo" />
          <div className="flex flex-col gap-4 md:gap-[30px]">
            <h1 className="text-4xl md:text-app-6xl text-center bg-clip-text text-transparent bg-custom-gradient uppercase font-bold">
              Coming Soon
            </h1>
            <p className="text-white text-base md:text-2xl font-medium text-center">
              Discover Your Next Career Move!
            </p>
          </div>
          <form className="w-full px-0 md:px-[34.5px]" onSubmit={handleNotify}>
            <p className="uppercase font-bold text-sm md:text-app-base text-center mb-4 text-white">
              STAY IN THE LOOP
            </p>
            <div className="border border-white mb-2 w-full p-1 rounded-[5px] flex justify-between items-center">
              <input
                className="bg-transparent placeholder:text-white text-white placeholder:text-app-sm p-4 outline-none rounded-[5px] w-full"
                placeholder="Enter Email Address"
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
              />
              <button
                className="px-[30.5px] py-4 text-white bg-custom-gradient rounded-[5px] text-app-sm shadow-lg hover:opacity-90 transition-opacity duration-300 text-nowrap"
                disabled={loading}
              >
                {loading ? "Loading..." : "Notify Me"}
              </button>
            </div>
          </form>
          <p className="text-app-sm text-white text-center">
            *We promise not to spam your mail.
          </p>
        </div>
        <p className="text-white text-sm md:text-app-base mt-auto p-4">
          Copyright by <span className="font-medium">BLOCKHIRE</span>
        </p>

        {/* <p className="text-white text-sm md:text-app-base mt-10 2xl:mt-0 relative 2xl:absolute 2xl:bottom-[2rem]">
          Copyright by <span className="font-medium">BLOCKHIRE</span>
        </p> */}
      </div>
    </section>
  );
};

export default ComingSoon;
